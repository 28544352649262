import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MigrationsService {
  constructor(private http: HttpClient) {
  }

  getMigrationsForToday(): Observable<CollectionMigration[]> {
    const url = `${environment.apiEndpoint}/migrations/today`;
    return this.http.get<CollectionMigration[]>(url);
  }

  getAllowedMigrations(): Observable<string[]> {
    const url = `${environment.apiEndpoint}/migrations/allowed`;
    return this.http.get<string[]>(url);
  }

  initiateMigration(target: string, collectionId: string): Observable<boolean> {
    const url = `${environment.apiEndpoint}/migrations/initiate?target=${target}&collectionId=${collectionId}`;
    return this.http.post<boolean>(url, {});
  }
}


export interface CollectionMigration {
  id: number;
  sourceCollectionId: string;
  sourceCollectionName: string;
  sourceCollectionDescription: string;
  initiatedBy: string;
  createdDateTimeUtc: Date;
  lastAttemptedDateTimeUtc: Date | null;
  lastAttemptedComments: string | null;
  targetCollectionId: string;
  stagedRevisionId: string;
  sourcePartitionId: string;
  collectionMigrationStatusId: number;
  collectionMigrationStatus: CollectionMigrationStatus;
}

export interface CollectionMigrationStatus {
  id: number;
  name: string;
  queued: number;
  migrating: number;
  staged: number;
  completed: number;
  failed: number;
  timedOut: number;
}
