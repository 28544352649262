import { Inject, Injectable } from '@angular/core';
import { CalAngularService } from "@cvx/cal-angular";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, first, firstValueFrom, interval, lastValueFrom, Observable, of, Subscription, tap } from "rxjs";
import { AuthStatus } from "../models/auth-status";
import { LOCAL_STORAGE, SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { SlbTokenResponse } from "../models/slb-token-response";
import { environment } from "../../../environments/environment";
import { catchError, map, switchMap } from "rxjs/operators";
import { BuService } from "../api/bu.service";
@Injectable({ providedIn: 'root' })
export class AuthService {

  static calConfig = {
    autoSignIn: false,
    popupForLogin: false,
    cacheLocation: "localStorage",
    instance: "https://login.microsoftonline.com/",
    tenantId: environment.calTenantId,
    clientId: environment.calClientId,
    redirectUri: (() => {
      const url = new URL(window.location.href);
      const port = !!url.port ? `:${url.port}` : "";
      return `${url.protocol}//${url.hostname}${port}`;
    })(),
    oidcScopes: ["openid", "profile", "User.Read", "offline_access"],
    graphScopes: [".default"]
  };


  private _currentAuthStatus = new BehaviorSubject<AuthStatus>(null);
  currentUser: import("@azure/msal-browser").AccountInfo = null;

  get currentAuthStatus(): Observable<AuthStatus> {
    return this._currentAuthStatus.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private calAngularService: CalAngularService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService<string>,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService<string>,
    private window: Window,
    private buService: BuService
  ) {
    this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'authentication not attempted yet' });
    this.currentAuthStatus.pipe(
      switchMap(status => {
        if (status.userLoggedIn) {
          return buService.setDefaultCurrentBuIfNotSet().pipe(map(status => status));
        }
        return of(status);
      })).subscribe(console.log);
  }

  authenticateUser(): Observable<boolean> {
    return this.calAngularService.isUserSignedIn().pipe(switchMap(isUserSignedIn => {
      if (isUserSignedIn === true) {
        this.currentUser = this.calAngularService.getAccount();
        return this.getSlbToken();
      } else {
        this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'cal authentication failed! initiating sign-in' });
        return this.calAngularService.userInitiatedSignIn().pipe(map(p => {
          throw new Error('cal authentication being done');
        }));
      }
    }));
  }


  getSlbToken(): Observable<boolean> {
    const url = new URL(this.window.location.href);
    const code = url.searchParams.get('code');
    const slbToken = url.searchParams.get('slbToken');
    if(!!slbToken)
      return this.redirectToSlbAuth();
    const request = !!!code ?
      this.httpClient.get<SlbTokenResponse>(`${environment.apiEndpoint}/SlbAuthentication/Token`) :
      this.httpClient.post<SlbTokenResponse>(`${environment.apiEndpoint}/SlbAuthentication/Authenticate`,
        { code: code, redirectUrl: AuthService.calConfig.redirectUri }
      );

    return request
      .pipe(switchMap(tokenResponse => {
        if (!!tokenResponse.token) {
          this._currentAuthStatus.next({ userLoggedIn: true, statusMessage: 'authenticated from slb refresh token' });
          return of(true);
        }
        if (!!!code) {
          return this.redirectToSlbAuth();
        }
        this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'slb authentication failed!' });
        return of(false);
      }), catchError(err => {
        console.error(err);
        if (!!!code)
          return this.redirectToSlbAuth();
        else {
          this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'slb authentication failed!' });
        }
      }));
  }

  redirectToSlbAuth(): Observable<boolean> {
    const url = new URL(this.window.location.href);
    const currentPathRequested = `${url.pathname}`;
    this.sessionStorage.set(currentPathRequested, `${url.search}`);
    return this.httpClient.get<{ authCodeUrl: string }>(
      `${environment.apiEndpoint}/SlbAuthentication/AuthCodeUrl?redirectUrl=${AuthService.calConfig.redirectUri}&state=${currentPathRequested}`)
      .pipe(map(authCodeUrlRes => {
        this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'redirecting to slb for auth!' });
        this.window.location.href = authCodeUrlRes.authCodeUrl.replace(/"/g, '');
        return true;
      }));
  }

  public getScopes(): string[] {
    return [`${environment.calClientId}/user_impersonation`];
  }

  public logout():Observable<boolean> {
    return  this.httpClient.post(`${environment.apiEndpoint}/SlbAuthentication/Logout`,
      {}
    ).pipe(map(_ => {
      return true;
    }));
  }

}
