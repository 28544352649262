<ng-template #pageHeaderOptions>
  <div class="page-header-toolbar">
    <div class="toolbar-row" *ngIf="access !== 'none' && collection">
      <div class="toolbar-flex-container">
        <div class="toolbar-button-container">
          <button mat-mini-fab color="primary" aria-label="reload collection" matTooltip="reload collection"
            (click)="callRefreshCollection()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="toolbar-button-container">
          <button mat-mini-fab color="primary" [matMenuTriggerFor]="migrationsMenu">
            <mat-icon>cloud_upload</mat-icon></button>
            <mat-menu #migrationsMenu="matMenu">
              <button mat-menu-item *ngFor="let migration of allowedMigrations" (click)="initiateMigration(migration)">
                <span>{{migration}}</span>
            </button>
            </mat-menu>
        </div>
        <div class="toolbar-button-container">
          <button mat-raised-button color="primary" (click)="uploadModel()"
            [disabled]="isReadOnly() || uploadInProgress">
            <mat-icon>collections</mat-icon>
            <span>Upload Model</span></button>
          <mat-progress-spinner mode="indeterminate" *ngIf="uploadInProgress" diameter="20">
          </mat-progress-spinner>
        </div>
        <div class="toolbar-button-container">
          <button mat-raised-button #matMenuTrigger color="primary" [disabled]="downloadInProgress" [matMenuTriggerFor]="menu">
            <mat-icon>download</mat-icon>
            <span>Download Collection</span></button>
            <mat-menu #menu="matMenu">
              <button color="primary" mat-raised-button *ngIf="!!toBeDownloadedModels && toBeDownloadedModels.length > 10" (click)="downloadCollection()">Download Selected Model(s)</button>
              <mat-checkbox #menuItems (click)="$event.stopPropagation()"  (change)="downloadAllModelsSelect()" [(ngModel)]="downloadAllModels">
                All Models
              </mat-checkbox>
              <mat-checkbox #menuItems (click)="$event.stopPropagation()" (change)="downloadModelSelectionChanged()" *ngFor="let collectionModel of toBeDownloadedModels"  [(ngModel)]="collectionModel.selected">
                {{collectionModel.name.substring(collectionModel.name.lastIndexOf('/') + 1)}}
              </mat-checkbox>
              <button color="primary" mat-raised-button (click)="downloadCollection()">Download Selected Model(s)</button>
            </mat-menu>
          <mat-progress-spinner mode="indeterminate" *ngIf="downloadInProgress" diameter="20">
          </mat-progress-spinner>
        </div>
        
        <div class="toolbar-button-container">
          <button mat-raised-button color="primary" (click)="onTimeline()" [disabled]="isReadOnly()">
            <mat-icon>history</mat-icon>
            <span>Timeline</span></button>
        </div>
        <div class="toolbar-button-container">
          <button mat-mini-fab color="warn" aria-label="delete collection" matTooltip="delete collection"
            (click)="deleteCollection()" [disabled]="isReadOnly()">
            <mat-icon>delete_sweep</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<mat-drawer-container class="drawer-container" autosize>
  <mat-drawer #drawer class="drawer-sidenav" [style.width.%]="modelTreeCollapserWidth" mode="side">
    <app-model-tree [modelToFiles]="modelToFiles" [retrievedFiles]="selectedModelToFiles" [forceSelectModelId]="modelTreeSelectedModelId"
      (modelsContextChange)="onModelContextChange($event)" (modelTypeChange)="onModelTypeChange($event)">
    </app-model-tree>
  </mat-drawer>
  <div>
    <ng-template *ngIf="!modelToFiles || modelToFiles?.length === 0">
      <div>
      </div>
      <div>
        <span *ngIf="access !== 'none';else no_access">no models found in this collection yet. please browse model
          folder
          for uploading a model</span>
        <ng-template #no_access>no access for the user to view this collection</ng-template>
      </div>
    </ng-template>
    <app-model-folders *ngIf="!!modelToFiles && modelToFiles.length > 0" [modelToFiles]="selectedModelToFiles" [modelType]="selectedModelType" [missingInputFiles]="missingInputFiles"
      (addFileToSimulation)="onAddFileToSimulation($event)" [isReadOnly]="isReadOnly()"
      (uploadFiles)="onUploadFiles($event)" (downloadModel)="onDownloadModel($event)"></app-model-folders>
  </div>
</mat-drawer-container>