import { Inject, Injectable } from '@angular/core';
import { CollectionMigration, MigrationsService } from './migrations.service';
import { Observable } from 'rxjs';
import { SlbNotificationItem } from '@slb-dls/angular-material/notifications-panel/models/notification-item';
import { map } from 'rxjs/operators';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  private readonly readKey: string = 'read-notifications';
  private readonly removedKey: string = 'removed-notifications';
  private readonly dateKey: string = 'notifications-date';

  constructor(
    private migrationsService: MigrationsService,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService<UserNotification[]>,
    @Inject(LOCAL_STORAGE) private dateStorage: StorageService<number>
  ) {}

  getAllNotificationsForToday(): Observable<UserNotification[]> {
    const dayOfMonth = new Date().getDate();
    let readNotifications = [];
    let removedNotifications = [];
    if (dayOfMonth !== this.dateStorage.get(this.dateKey)) {
      this.dateStorage.set(this.dateKey, dayOfMonth);
      this.localStorage.set(this.readKey, readNotifications);
      this.localStorage.set(this.removedKey, removedNotifications);
    } else {
      readNotifications = this.localStorage.get(this.readKey);
      removedNotifications = this.localStorage.get(this.removedKey);
    }

    return this.migrationsService.getMigrationsForToday().pipe(
      map(migrations => {
        return migrations
          .filter(p => {
            return !removedNotifications.some(r => this.compareMigrationToNotification(p, r));
          })
          .map(p => {
            return {
              read: readNotifications.some(r => this.compareMigrationToNotification(p, r)),
              data: p,
              type: 'Migration',
              text: `${p.sourceCollectionName} - ${p.collectionMigrationStatus.name}`,
              date: new Date(`${p.lastAttemptedDateTimeUtc ?? p.createdDateTimeUtc}z`)
            } as UserNotification;
          });
      })
    );
  }

  private compareMigrationToNotification(migration: CollectionMigration, notification: UserNotification): boolean {
    return (
      notification.data.id === migration.id &&
      notification.type === 'Migration' &&
      migration.collectionMigrationStatusId === notification.data.collectionMigrationStatusId
    );
  }

  markAsRead(userNotification: UserNotification) {
    let existingValues = this.localStorage.get(this.readKey);
    if (!existingValues) existingValues = [];
    existingValues.push(userNotification);
    this.localStorage.set(this.readKey, existingValues);
  }

  removeNotification(userNotification: UserNotification) {
    let existingValues = this.localStorage.get(this.removedKey);
    if (!existingValues) existingValues = [];
    existingValues.push(userNotification);
    this.localStorage.set(this.removedKey, existingValues);
  }
}

export interface UserNotification extends SlbNotificationItem {
  data: any;
  type: 'Migration';
}
