import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { autoUnsubscribe } from "../../../../core/decorators/auto-unsubscribe.decorator";
import { BreadcrumbsService } from "../../../../core/services/breadcrumbs.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { concatMap, debounceTime, delay, map, switchMap, throttle, withLatestFrom } from "rxjs/operators";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  first, interval, last,
  Observable,
  of,
  Subject,
  Subscription,
  tap, zip
} from "rxjs";
import { Collection } from "../../../../core/models/collection";
import { ModelFile } from "../../../../core/models/model-file";
import { Simulation } from "../../../../core/models/export class simulation";
import { MatDialog } from "@angular/material/dialog";
import { UploadModelDialog, UploadModelDialogData, UploadModelInfo } from "./dialogs/upload-model.dialog";
import { ModelFilesService } from "../../../../core/services/model-files.service";
import { NewFileAdd } from "./view-model-folders.component";
import * as moment from 'moment';
import { ModelUploadInfo } from "../../../../core/api/collection-files-uploader.service";
import { CollectionsNeededService } from "../collectionsneeded.service";
import { ViewCollectionPartOneNeededService } from "./view-collectionpartoneneeded.service";
import { ViewCollectionPartTwoNeededService } from "./view-collectionparttwoneeded.service";
import { ModelFromQuery } from "src/app/core/models/query-models-response";
import { StageSimulationResponse } from "src/app/core/api/simulation.service";
import { ModelUploadTaskInProgressService } from "src/app/core/services/model-upload-task-in-progress.service";
import { MonitoringService } from "src/app/core/services/monitoring.service";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { ModelDownloadTaskInProgress, ModelDownloadTaskInProgressService } from "src/app/core/services/model-download-task-in-progress.service";
import { UploadTasksTableDialogComponent } from "src/app/components/shared/tasks-in-progress/upload-tasks-table-dialog.component";
import { CollectionUserAccessService } from "src/app/core/api/collection-user-access.service";
import { ViewCollectionPartThreeNeededService } from "./view-collectionpartthreeneeded.service";
import { MatDrawer } from "@angular/material/sidenav";
import { startsWith } from "lodash";


export interface ModelToFiles {
  model: Simulation,
  files: ModelFile[]
}

@autoMonitorPageView({ name: 'View Collection', trackOnInitToAfterInit: false })
@Component({
  selector: 'app-view-collection',
  templateUrl: './view-collection.component.html',
  styleUrls: ['./view-collection.component.scss']
})
@autoUnsubscribe({ autoInclude: true })
export class ViewCollectionComponent implements AfterViewInit, OnDestroy, OnInit {
  private collection$: Subscription;
  public collection: Collection;
  private collectionModels: Simulation[];
  toBeDownloadedModels: { id: string, name: string, selected: boolean }[];
  private refreshModelFolders$ = Subscription.EMPTY;

  @ViewChild("pageHeaderOptions")
  private pageHeaderOptionsTpl: TemplateRef<any>;

  downloadAllModels = true;
  modelToFiles: ModelToFiles[] = [];
  selectedModelToFiles: ModelToFiles = null;
  selectedModelType: string;
  modelTreeSelectedModelId: string;
  missingInputFiles: File[];
  downloadInProgress: boolean = false;
  uploadInProgress: boolean = false;
  private addFile: Subject<{ model: Simulation, file: NewFileAdd }> = new Subject<{ model: Simulation, file: NewFileAdd }>();
  private retrieveFiles: Subject<Simulation> = new Subject<Simulation>();
  private addFile$: Subscription;
  private activatedRouteParam$: Subscription;

  private bindCollectionRequest$: Subscription;
  private bindCollectionRequest = new BehaviorSubject(true);
  private collectionId: string;
  private revisionId: string;
  private downloadTasksInProgressService$: Subscription;
  private allowedMigrations$: Subscription;
  public access: 'all' | 'read' | 'none';
  private uploadTasksInProgress$: Subscription;
  private retrieveFiles$: Subscription;
  public allowedMigrations: string[] = []


  get modelTreeCollapserWidth(): number {
    return this.threeNeededService.modelTreeCollapserService.width;
  }

  @ViewChild('drawer') set drawer(d: MatDrawer) {
    if (d) {
      this.threeNeededService.modelTreeCollapserService.init(d);
    }
  };


  constructor(
    public dialog: MatDialog,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private firstService: CollectionsNeededService,
    private secondService: ViewCollectionPartOneNeededService,
    private thirdService: ViewCollectionPartTwoNeededService,
    private threeNeededService: ViewCollectionPartThreeNeededService,
    private monitoringService: MonitoringService,
  ) {

    this.bindCollectionRequest$ = this.bindCollectionRequest.pipe(switchMap(() => {
      if (this.revisionId) {
        this.firstService.breadcrumbsService.setCurrentBreadcrumbItems(
          [BreadcrumbsService.COLLECTIONS,
          Object.assign(BreadcrumbsService.COLLECTION, { disabled: false, url: `/collection/${this.collectionId}` }),
          Object.assign(BreadcrumbsService.COLLECTION_REVISION, { disabled: true })
          ]);
      } else {
        this.firstService.breadcrumbsService.setCurrentBreadcrumbItems(
          [BreadcrumbsService.COLLECTIONS
          ]);
      }
      if (this.pageHeaderOptionsTpl) //this is put in since just the query change will reset the toolbar
        this.firstService.pageHeaderService.setCurrentToolbarTemplate(this.pageHeaderOptionsTpl);

      return this.bindCollectionDetails(this.collectionId, this.revisionId);
    }), debounceTime(100)).subscribe();


    this.collection$ = this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => {
        this.collectionId = params.get('id')!
        this.bindCollectionRequest.next(true);
        return true;
      })
    ).subscribe();

    this.activatedRouteParam$ = this.activatedRoute.queryParams.pipe(tap(p => {
      this.modelTreeSelectedModelId = p.m;
      this.revisionId = p.r;
      this.bindCollectionRequest.next(true);
    })).subscribe();

    this.addFile$ = this.addFile.pipe(concatMap((newFileAdd: { model: Simulation; file: NewFileAdd }) => {
      const modelToFiles = [...this.modelToFiles] as ModelToFiles[];
      const context = modelToFiles.find(p => p.model.id === newFileAdd.model.id);

      let obs;
      switch (context.model.updateInfo?.mode) {
        case 'add':
          obs = this.addNewFileInAddMode(modelToFiles, context, newFileAdd);
          break;
        case 'update':
          obs = this.addNewFileInUpdateMode(modelToFiles, context, newFileAdd);
          break;
        default:
          obs = this.addNewFileInViewMode(modelToFiles, context, newFileAdd);
          break;
      }
      return obs;
    }), tap((modelToFiles: ModelToFiles[]) => {
      this.modelToFiles = [...modelToFiles];
    })).subscribe();

    this.retrieveFilesSubscribe();
  }

  isReadOnly = (): boolean => !!this.revisionId || this.access !== 'all';

  private retrieveFilesUnSubscribe() {
    if (this.retrieveFiles$) this.retrieveFiles$.unsubscribe();
  }

  private retrieveFilesSubscribe() {
    this.retrieveFiles$ = this.retrieveFiles.pipe(switchMap(model => {
      this.firstService.loadingService.showSpinner({ text: 'retrieving model details' })

      const contextModel = this.modelToFiles.find(p => p.model.id === model.id);
      if (contextModel.files.length > 0) {
        this.modelTreeSelectedModelId = contextModel.model.id;
        this.selectedModelToFiles = Object.assign({}, contextModel);
        return of(true);
      } else {
        return this.getSimulationFiles(model).pipe(tap(modelToFiles => {
          contextModel.model = modelToFiles.model;
          contextModel.files = modelToFiles.files;
          this.modelTreeSelectedModelId = contextModel.model.id;
          this.selectedModelToFiles = Object.assign({}, contextModel);
        }), map(_ => true));
      }
    })).subscribe({
      next: () => {
        this.firstService.loadingService.closeSpinner();
      },
      error: () => {
        this.firstService.loadingService.closeSpinner();
      }
    });
  }

  public ngOnInit() {
    this.refreshModelFolders$ = this.secondService.collectionsService.refreshModelFolders.subscribe(refreshModel => {
      if (refreshModel) this.callRefreshCollection();
    });

    this.allowedMigrations$ = this.threeNeededService.migrationsService.getAllowedMigrations().subscribe(allowedMigrations => {
      this.allowedMigrations = allowedMigrations;
    });

    this.downloadTasksInProgressService$ = this.thirdService.modelDownloadTaskInProgressService.parentTasksInProgress$.pipe(tap(parentTasks => {
      this.downloadInProgress = parentTasks.length > 0;
    })).subscribe();

    this.uploadTasksInProgress$ = this.thirdService.modelUploadTaskInProgressService.parentTasksInProgress$.pipe(
      withLatestFrom(this.thirdService.modelUploadTaskInProgressService.completedTask$),
      tap(([parentTasks, completedTask]) => {
        const prevUploadTaskInProgress = this.uploadInProgress;
        this.uploadInProgress = parentTasks.length > 0;
        if (!this.uploadInProgress && prevUploadTaskInProgress) {
          if (completedTask?.Status === ModelUploadTaskInProgressService.TaskSuccessStatus) {
            this.firstService.toastrService.success('Model submitted was successful');
            this.callRefreshCollection();
          } else if (completedTask?.Status === ModelUploadTaskInProgressService.TaskFailureStatus) {
            this.firstService.toastrService.success('Model submitted has failed. Please contact support');
            this.callRefreshCollection();
          }
        }
      })).subscribe();

    this.threeNeededService.recordAccess(this.collectionId);
  }

  private bindCollection(collectionId: string): Observable<Collection> {
    return zip(this.secondService.collectionsService.getCollection(collectionId), this.firstService.entitlementsService.getMyGroups()).pipe(first(), map(([collection, myGroups]) => {
      if (collection.owners.some(p => myGroups.some(x => x.email === p))) {
        this.access = 'all';
      } else if (collection.viewers.some(p => myGroups.some(x => x.email === p))) {
        this.access = 'read';
      } else {
        this.access = 'none';
        throw new Error('no access');
      }

      this.collection = collection;
      this.firstService.pageHeaderService.setPageTitle(collection.name);
      return collection;
    }))
  }

  private bindCollectionDetails(collectionId: string, revisionId: string): Observable<boolean> {
    this.firstService.loadingService.showSpinner({ text: 'retrieving collection details' });
    const existingModelIds = this.modelToFiles.map(p => p.model.id);
    this.modelToFiles = [];
    return zip([this.bindCollection(collectionId), this.bindCollectionModels(collectionId, revisionId)]).pipe(map(([collectionDetails, allModelFiles]) => {
      allModelFiles.forEach(modelFile => {
        if (modelFile.model.collectionId === collectionDetails.id) {
          modelFile.model.collectionName = collectionDetails.name;
        }
      });
      if (existingModelIds.length > 0) {
        const updatedModelsIds = allModelFiles.map(p => p.model.id);
        const newModelId = updatedModelsIds.find(x => !existingModelIds.some(y => x === y));
        if (newModelId) this.modelTreeSelectedModelId = newModelId;
      }
      this.modelToFiles = allModelFiles;
      this.firstService.loadingService.closeSpinner(true);
      return true;
    }), catchError(err => {
      this.firstService.loadingService.closeSpinner(true);
      throw new Error('error in binding collection details ' + err);
    }));
  }



  private bindCollectionModels(collectionId: string, revisionId: string): Observable<ModelToFiles[]> {
    this.modelToFiles = [];
    const serviceToBeCalled = (!!revisionId) ? this.thirdService.revisionsService.getSimulationsForRevision(revisionId) : this.secondService.collectionsService.getSimulationsForCollection(collectionId);
    return serviceToBeCalled.pipe(switchMap((models: Simulation[]) => {
      this.collectionModels = models;
      this.toBeDownloadedModels = models.map(p => {
        return {
          id: p.id,
          name: p.name,
          selected: true
        };
      });
      if (models.length === 0) return of([]);
      if (!this.modelTreeSelectedModelId) this.modelTreeSelectedModelId = models[0].id;
      const simulationsFiles: ModelToFiles[] = this.collectionModels.map(p => {
        return { model: p, files: [] };
      });
      return of(simulationsFiles);
    }))
  }


  // private bindCollectionModels(collectionId: string, revisionId: string): Observable<ModelToFiles[]> {
  //   this.modelToFiles = [];
  //   const serviceToBeCalled = (!!revisionId) ?
  //     [this.thirdService.revisionsService.getSimulationsForRevision(revisionId), this.thirdService.revisionsService.getFilesForRevision(revisionId)] :
  //     [this.secondService.collectionsService.getSimulationsForCollection(collectionId), this.secondService.collectionsService.getFilesForCollection(collectionId)];
  //   return zip(serviceToBeCalled).pipe(map(([models, files]) => {
  //     this.collectionModels = models as Simulation[];
  //     if (models.length === 0) return [];
  //     if (!this.modelTreeSelectedModelId) this.modelTreeSelectedModelId = models[0].id;
  //     const simulationsFiles = this.bindCollectionFiles(models as Simulation[], files as ModelFile[]);
  //     simulationsFiles.forEach(modelToFiles => {
  //       const rootFile = modelToFiles.model.name;
  //       modelToFiles.model.hasManifest = !!modelToFiles.files.find(p => p.path === `${rootFile}.manifest`);

  //       modelToFiles.files.forEach(modelFile => {
  //         modelFile.references = simulationsFiles.filter(p => p.model.id !== modelToFiles.model.id && p.files.some(x => x.path === modelFile.path)).map(p => p.model.name);
  //       });
  //     });
  //     this.modelToFiles = [...simulationsFiles];
  //     return simulationsFiles;

  //     // const simulationFilesRequests = this.collectionModels.map(p => this.bindSimulationFiles(p));
  //     // return zip(simulationFilesRequests).pipe(map((simulationsFiles: ModelToFiles[]) => {
  //     //   simulationsFiles.forEach(modelToFiles => {
  //     //     const rootFile = modelToFiles.model.name;
  //     //     modelToFiles.model.hasManifest = !!modelToFiles.files.find(p => p.path === `${rootFile}.manifest`);

  //     //     modelToFiles.files.forEach(modelFile => {
  //     //       modelFile.references = simulationsFiles.filter(p => p.model.id !== modelToFiles.model.id && p.files.some(x => x.path === modelFile.path)).map(p => p.model.name);
  //     //     });
  //     //   });
  //     //   this.modelToFiles = [...simulationsFiles];
  //     //   return simulationsFiles;
  //     // }));
  //   }))
  // }

  private getSimulationFiles(simulation: Simulation): Observable<ModelToFiles> {
    return this.secondService.modelsService.getFilesForModel(simulation.id).pipe(map(files => {
      const primaryFile = files.find(p => p.path === simulation.name);
      if (primaryFile) primaryFile.isPrimaryFile = true;
      const rootFile = simulation.name;
      simulation.hasManifest = !!files.find(p => p.path === `${rootFile}.manifest`);
      return { model: simulation, files: files } as ModelToFiles;
    }));
  }

  ngOnDestroy(): void {
    //for autoUnsubscribe to unsubscribe the observables
    console.log('onDestroy view-collection-component');
  }


  ngAfterViewInit(): void {
    this.firstService.pageHeaderService.setCurrentToolbarTemplate(this.pageHeaderOptionsTpl);

  }

  uploadModel() {
    this.thirdService.md5HashService.cleanUpAll();
    const dialogRef = this.dialog.open(UploadModelDialog, {
      width: '50vw',
      disableClose: true,
      data: { existingSimulationModels: this.collectionModels } as UploadModelDialogData
    });

    dialogRef.afterClosed().pipe(first(), switchMap((result: UploadModelInfo) => {
      if (result) {
        this.retrieveFilesUnSubscribe();
        return zip([this.bindCollectionDetails(this.collection.id, this.revisionId).pipe(switchMap(_ => {
          if (result.selectedModel.simulationMatched) {
            return this.getSimulationFiles(result.selectedModel.simulationMatched).pipe(tap(modelToFiles => {
              const context = this.modelToFiles.find(p => p.model.id === modelToFiles.model.id);
              context.model = modelToFiles.model;
              context.files = modelToFiles.files;
            }));
          }
          return of(true);
        })),
        this.secondService.modelFilesService.getModelFilesInfoFromFiles(result.selectedModel.modelFile, result.files),
        this.secondService.modelFilesService.getMissingInputFiles(result.selectedModel.modelFile, result.files)])
          .pipe(first(), map(([_, modelFiles, missingInputModelFiles]) => {
            this.thirdService.md5HashService.cleanUpAll();
            this.missingInputFiles = missingInputModelFiles;
            this.findConflictsFromNewlyAddedFiles(modelFiles);
            this.setUpdateModeForConflicts(modelFiles);
            this.addUploadedModelFilesToExistingSimulations(result, modelFiles);
          }));
      } else return EMPTY;
    })).subscribe(_ => {
      this.retrieveFilesSubscribe();
    });
  }

  private setUpdateModeForConflicts(modelFiles: ModelFile[]): void {
    modelFiles.forEach(p => {
      if (p?.updateInfo?.conflictsWithChangesInSizeAndDate?.length > 0 || p?.updateInfo?.conflictsWithSameSizeAndDate?.length > 0) {
        p.updateInfo.mode = 'update';
      }
    })
  }



  private calculateHashForFile = (modelFile: ModelFile): void => {
    const sub$ = this.thirdService.md5HashService.calculateForFile(modelFile.updateInfo.updatedFile).pipe(tap(p => {
      modelFile.generatedMd5 = p;
    })).subscribe(_ => {
      if (sub$) {
        sub$.unsubscribe();
      }
    });
  }

  private addUploadedModelFilesToExistingSimulations(uploadModelInfo: UploadModelInfo, modelFiles: ModelFile[]): void {
    let modelToFiles: any[];
    if (uploadModelInfo.selectedModel.simulationMatched) { //edit
      modelToFiles = this.addUploadedModelFilesInUpdateMode(uploadModelInfo, modelFiles);
    } else { // add
      modelToFiles = this.addUploadedModelFilesInAddMode(uploadModelInfo, modelFiles);
    }
    this.modelToFiles = [...modelToFiles];
  }

  private addUploadedModelFilesInAddMode(uploadModelInfo: UploadModelInfo, modelFiles: ModelFile[]): ModelToFiles[] {
    const modelToFiles = JSON.parse(JSON.stringify(this.modelToFiles.filter(p => !p.model.updateInfo))) as ModelToFiles[];
    const simulation = this.secondService.modelFilesService.createModelInfoFromModelFile(this.collection, uploadModelInfo.selectedModel.modelFile);
    modelToFiles.push({ model: simulation, files: modelFiles });
    this.modelTreeSelectedModelId = simulation.id;
    modelFiles.filter(p => (p.updateInfo?.conflictsWithChangesInSizeAndDate?.length ?? 0) === 0
      && (p.updateInfo?.conflictsWithSameSizeAndDate?.length ?? 0) === 0).forEach(modelFileToBeSelected => {
        if ((modelFileToBeSelected.updateInfo.updatedFile.size === 0) || (this.secondService.fileUtilsService.getFileExtension(modelFileToBeSelected.path) === 'manifest')) modelFileToBeSelected.updateInfo.mode = 'not-allowed';
        else {
          modelFileToBeSelected.updateInfo.selected = !(modelFileToBeSelected.updateInfo.optional);
          this.calculateHashForFile(modelFileToBeSelected);
        }

      });
    return modelToFiles;
  }


  private addUploadedModelFilesInUpdateMode(uploadModelInfo: UploadModelInfo, modelFiles: ModelFile[]): ModelToFiles[] {
    const modelToFiles = JSON.parse(JSON.stringify(this.modelToFiles.filter(p => !p.model.updateInfo))) as ModelToFiles[];
    const simulationToModelFiles = modelToFiles.find(p => p.model.id === uploadModelInfo.selectedModel.simulationMatched.id);
    simulationToModelFiles.model.updateInfo = { mode: 'update' };
    const constructedModelFiles = modelFiles;
    this.modelTreeSelectedModelId = simulationToModelFiles.model.id;

    //deletions and updates first
    simulationToModelFiles.files.forEach(existingModelFile => {
      const constructedModelFile = constructedModelFiles.find(p => p.path === existingModelFile.path);
      if (constructedModelFile) {
        if (constructedModelFile.updateInfo.updatedFile.size === 0) {
          existingModelFile.updateInfo = {
            mode: 'not-allowed',
            updatedFile: null
          };
        } else
          existingModelFile.updateInfo = {
            mode: 'update',
            conflictsWithChangesInSizeAndDate: constructedModelFile.updateInfo.conflictsWithChangesInSizeAndDate.filter(p => p !== simulationToModelFiles.model.name),
            conflictsWithSameSizeAndDate: constructedModelFile.updateInfo.conflictsWithSameSizeAndDate.filter(p => p !== simulationToModelFiles.model.name),
            sizeOrDateDifferenceWithUpdatedFile: (existingModelFile.size !== constructedModelFile.size || moment(existingModelFile.fileModifiedDateTime).diff(moment(constructedModelFile.fileModifiedDateTime), 'seconds') !== 0),
            updatedFile: constructedModelFile.updateInfo.updatedFile
          };
      } else if (this.secondService.fileUtilsService.getFileExtension(existingModelFile.path) === 'manifest') {
        existingModelFile.updateInfo = {
          mode: 'not-allowed',
          updatedFile: null
        };
      } else {
        existingModelFile.updateInfo = { mode: 'delete', updatedFile: null };
      }
    });

    constructedModelFiles.filter(p => !simulationToModelFiles.files.some(x => p.path === x.path)).forEach(constructedModelFile => {
      if (constructedModelFile.updateInfo.updatedFile.size === 0) {
        constructedModelFile.updateInfo = {
          mode: 'not-allowed',
          updatedFile: null
        };
      }
      simulationToModelFiles.files.push(constructedModelFile);
    });
    simulationToModelFiles.files.filter(p => (p.updateInfo?.mode === 'add' ||
      p.updateInfo?.mode === 'delete' ||
      (p.updateInfo?.mode === 'update' && p.updateInfo?.sizeOrDateDifferenceWithUpdatedFile)
    )).forEach(modelFileToBeSelected => {
      modelFileToBeSelected.updateInfo.selected = !(modelFileToBeSelected.updateInfo.optional);
      if (modelFileToBeSelected.updateInfo?.mode === 'add' || modelFileToBeSelected.updateInfo?.mode === 'update')
        this.calculateHashForFile(modelFileToBeSelected);
    });
    return modelToFiles;
  }

  private findConflictsFromNewlyAddedFiles(modelFiles: ModelFile[], notInModelName?: string) {
    modelFiles.forEach(modelFile => {
      modelFile.updateInfo.conflictsWithChangesInSizeAndDate = this.modelToFiles.filter(p => notInModelName !== p.model.name && p.files.some(x => x.path === modelFile.path && (x.size !== modelFile.size || moment(x.fileModifiedDateTime).diff(moment(modelFile.fileModifiedDateTime), 'seconds') !== 0))).map(p => p.model.name);
      modelFile.updateInfo.conflictsWithSameSizeAndDate = this.modelToFiles.filter(p => notInModelName !== p.model.name && p.files.some(x => x.path === modelFile.path && x.size === modelFile.size && moment(x.fileModifiedDateTime).diff(moment(modelFile.fileModifiedDateTime), 'seconds') !== 0)).map(p => p.model.name);
    });
  }

  onModelContextChange(modelContext: ModelToFiles & { retrieveFiles: boolean }) {
    this.selectedModelToFiles = Object.assign({}, modelContext);
    if (modelContext.retrieveFiles)
      this.retrieveFiles.next(modelContext.model);
  }

  onModelTypeChange(modelType: string) {
    this.selectedModelType = modelType;
  }

  refreshCollection(): Observable<boolean> {
    return this.bindCollectionDetails(this.collection.id, this.revisionId).pipe(first());
  }

  onAddFileToSimulation(newFileAdd: { model: Simulation; file: NewFileAdd }) {
    if (newFileAdd.file.file.size !== 0 && this.secondService.fileUtilsService.getFileExtension(newFileAdd.file.path) !== 'manifest')
      this.addFile.next(newFileAdd);
  }

  private addNewFileInUpdateMode(modelToFiles: ModelToFiles[], context: ModelToFiles, newFileAdd: { model: Simulation; file: NewFileAdd }): Observable<ModelToFiles[]> {
    return of(true).pipe(switchMap(_ => {
      const existingFiles = context.files;
      const existingFile = existingFiles.find(p => p.path === `${newFileAdd.file.path}/${newFileAdd.file.file.name}`);
      if (existingFile) {
        if (existingFile.updateInfo.mode === 'delete') {
          console.warn(' you cannot add a file that is being deleted');
        } else {
          existingFile.updateInfo.updatedFile = newFileAdd.file.file;
          existingFile.updateInfo.selected = true;
          if (existingFile.updateInfo.mode === 'add') {
            existingFile.size = newFileAdd.file.file.size;
            existingFile.fileModifiedDateTime = new Date(newFileAdd.file.file.lastModified);
            this.findConflictsFromNewlyAddedFiles([existingFile], context.model.name);
          } else if (existingFile.updateInfo.mode === 'update') {
            existingFile.updateInfo.sizeOrDateDifferenceWithUpdatedFile = existingFile.size !== newFileAdd.file.file.size || moment(existingFile.fileModifiedDateTime).diff(moment(newFileAdd.file.file.lastModified), 'seconds') !== 0;
            existingFile.size = newFileAdd.file.file.size;
            existingFile.fileModifiedDateTime = new Date(newFileAdd.file.file.lastModified);
            this.findConflictsFromNewlyAddedFiles([existingFile], context.model.name);
          } else if (existingFile.updateInfo.mode === 'not-allowed' && this.secondService.fileUtilsService.getFileExtension(existingFile.path) !== 'manifest') {
            existingFile.updateInfo = {
              mode: 'update',
              selected: true,
              sizeOrDateDifferenceWithUpdatedFile: existingFile.size !== newFileAdd.file.file.size || moment(existingFile.fileModifiedDateTime).diff(moment(newFileAdd.file.file.lastModified), 'seconds') !== 0,
              updatedFile: newFileAdd.file.file
            };
            existingFile.size = newFileAdd.file.file.size;
            existingFile.fileModifiedDateTime = new Date(newFileAdd.file.file.lastModified);
            this.findConflictsFromNewlyAddedFiles([existingFile], context.model.name);
          }
          this.calculateHashForFile(existingFile);
        }
        return of(modelToFiles);
      } else {
        return this.addNewFileToModelToFiles(context, modelToFiles, newFileAdd);
      }
    }));

  }

  private addNewFileInAddMode(modelToFiles: ModelToFiles[], context: ModelToFiles, newFileAdd: { model: Simulation; file: NewFileAdd }): Observable<ModelToFiles[]> {
    return of(true).pipe(switchMap(_ => {
      const existingFiles = context.files;
      const existingFile = existingFiles.find(p => p.path === `${newFileAdd.file.path}/${newFileAdd.file.file.name}`);
      if (existingFile) {
        existingFile.size = newFileAdd.file.file.size;
        existingFile.fileModifiedDateTime = new Date(newFileAdd.file.file.lastModified);
        existingFile.updateInfo.updatedFile = newFileAdd.file.file;
        existingFile.updateInfo.selected = true;
        this.findConflictsFromNewlyAddedFiles([existingFile], context.model.name);
        return of(modelToFiles);
      } else {
        return this.addNewFileToModelToFiles(context, modelToFiles, newFileAdd);
      }
    }));

  }

  private addNewFileToModelToFiles(context: ModelToFiles, modelToFiles: ModelToFiles[], newFileAdd: { model: Simulation; file: NewFileAdd }): Observable<ModelToFiles[]> {
    const modelExt = this.secondService.fileUtilsService.getFileExtension(context.model.name);
    return this.secondService.fileUtilsService.getModelFileForAdd(newFileAdd.file.file, modelExt === ModelFilesService.IntersectExtension ? 'output' : 'input').pipe(first(),
      map((modelFile: ModelFile) => {
        modelFile.path = `${newFileAdd.file.path}/${newFileAdd.file.file.name}`;
        this.findConflictsFromNewlyAddedFiles([modelFile]);
        modelFile.updateInfo.selected = true;
        context.files.push(modelFile);
        this.calculateHashForFile(modelFile);
        modelFile.updateInfo.optional = true;
        return modelToFiles;
      }));
  }


  private addNewFileInViewMode(modelToFiles: ModelToFiles[], context: ModelToFiles, newFileAdd: { model: Simulation; file: NewFileAdd }): Observable<ModelToFiles[]> {
    return of(true).pipe(switchMap(_ => {
      context.model.updateInfo = { mode: 'update' };
      const existingFiles = context.files;
      existingFiles.forEach(existingFile => {
        existingFile.updateInfo = {
          mode: 'not-allowed',
          updatedFile: null
        }
      });
      const existingFile = existingFiles.find(p => p.path === `${newFileAdd.file.path}/${newFileAdd.file.file.name}`);
      if (existingFile) {
        existingFile.updateInfo = {
          mode: 'update',
          selected: true,
          sizeOrDateDifferenceWithUpdatedFile: existingFile.size !== newFileAdd.file.file.size || moment(existingFile.fileModifiedDateTime).diff(moment(newFileAdd.file.file.lastModified), 'seconds') !== 0,
          updatedFile: newFileAdd.file.file
        };
        existingFile.size = newFileAdd.file.file.size;
        existingFile.fileModifiedDateTime = new Date(newFileAdd.file.file.lastModified);
        this.findConflictsFromNewlyAddedFiles([existingFile], context.model.name);
        this.calculateHashForFile(existingFile);
        return of(modelToFiles);
      } else {
        return this.addNewFileToModelToFiles(context, modelToFiles, newFileAdd);
      }
    }));

  }


  getDistinctFiles(files: ModelFile[]): ModelFile[] {
    const distinctFiles: ModelFile[] = [];
    files.forEach(p => {
      if (!distinctFiles.some(x => x.path === p.path))
        distinctFiles.push(p);
    });
    return distinctFiles;
  }

  downloadCollection(): void {
    this.startDownloadAfterConfirmation(`Download ${this.collection.name}`, this.collection.name, this.toBeDownloadedModels.filter(p => p.selected).map(p => p.id));
  }


  onUploadFiles(model: Simulation): void {
    const selectedModelToFiles = this.modelToFiles.find(p => p.model.id === model.id && p.model.name === model.name);
    const modelToFiles = {
      model: Object.assign(selectedModelToFiles.model, { companionData: model.companionData }),
      selectedFiles: selectedModelToFiles.files.filter(p => p.updateInfo.selected),
      allFiles: selectedModelToFiles.files
    };
    if (modelToFiles.allFiles.length === 0) {
      this.firstService.toastrService.error('no files selected');
      return;
    }

    const obs = {
      next: (response) => {
        this.firstService.loadingService.closeSpinner(true);
      },
      error: (err) => {
        console.error('error uploading. the model', err);
        this.firstService.toastrService.error('could not upload the model. please contact support');
        this.firstService.loadingService.closeSpinner(true);
      }
    };
    this.firstService.loadingService.showSpinner({ text: "uploading the model" });
    this.thirdService.collectionFilesUploaderService.uploadModelToFiles(modelToFiles, (blobStorageDetails: StageSimulationResponse) => {
      this.firstService.loadingService.closeSpinner();
      const dialogRef = this.dialog.open(UploadTasksTableDialogComponent, {
        width: '90vw',
        height: '90vh',
        data: blobStorageDetails.containerName,
      });



      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
      return of(true).pipe(delay(1000));
    }).pipe(first(), tap(_ => {
      this.callRefreshCollection();
    })).subscribe(obs);
  }


  deleteCollection() {
    this.firstService.dialogService.deleteCollectionConfirmation().subscribe(confirmed => {
      if (confirmed) {
        this.firstService.loadingService.showSpinner({ text: "Deletion Request is being Sent..." });
        this.secondService.collectionsService.deleteCollectionsById({ collectionId: this.collection.id }).subscribe(deleteResponse => {
          if (deleteResponse.response?.isSuccessStatusCode === true) {
            this.secondService.collectionsService.setCollectionIdToDelete(deleteResponse.collectionId);
            this.router.navigate(['collections']);
          } else {
            this.firstService.toastrService.error("Could not delete collection!. You might not have delete permissions")
          }
          this.firstService.loadingService.closeSpinner();
        });
      }
    });
  }


  onDownloadModel(modelToBeDownloaded: ModelToFiles) {
    this.startDownloadAfterConfirmation(`Download model ${modelToBeDownloaded.model.name} of ${modelToBeDownloaded.model.collectionName}`, modelToBeDownloaded.model.name, [modelToBeDownloaded.model.id]);
  }

  startDownloadAfterConfirmation(title: string, nameInMessage: string, simulationIds: string[]): void {
    this.thirdService.collectionFilesDownloaderService.downloadFiles(this.collection.id, this.revisionId, title, nameInMessage, simulationIds);
  }

  callRefreshCollection(delay?: number): void {
    if (delay) {
      of(true).pipe(debounceTime(delay), tap(_ => {
        this.refreshCollection()
      })).subscribe();
    }
    else this.refreshCollection().subscribe();
  }

  onTimeline(): void {
    this.secondService.collectionsService.viewTimeline();
  }


  onExpandTree() {
    this.threeNeededService.modelTreeCollapserService.open();
  }

  downloadAllModelsSelect() {
    this.toBeDownloadedModels.forEach(p => {
      p.selected = this.downloadAllModels;
    });
  }

  downloadModelSelectionChanged() {
    this.downloadAllModels = !this.toBeDownloadedModels.some(p => !p.selected);
  }

  initiateMigration(target: string) {
    this.threeNeededService.migrationsService.initiateMigration(target, this.collectionId).subscribe({
      complete: () => {
        this.firstService.toastrService.success('Collection migration initiated');
       },
      error: () => {
        this.firstService.toastrService.error('Collection migration failed');
      }
    });
  }

}
